
const baseUrl = 'https://www.lh-holzbau.at/'
const apiPath = 'backend/api/'

module.exports = {
  env: 'prod',
  api: {
    dev: 'http://localhost:1235/' + apiPath,
    prod: baseUrl + apiPath
  },
  site: {
    name: 'LH Holzbau GmbH',
    title: 'LH Holzbau',
    keywords: [],
    description: '',
    image: 'static/social.jpg',
    baseUrl: baseUrl,
    language: 'de-AT',
    locale: 'de_DE',
    copyright: 'bisskonzept media OG',
    lastChange: 'April, 2019',
    logo: 'data/logo.svg',
    logoIcon: 'data/logo-icon.svg',
    terms: [{
      url: 'data/LH_Holzbau_AGB.pdf',
      title: 'Allgemeine Geschäftsbedingungen 09/2018'
    }, {
      url: 'data/LH_Holzbau_Datenschutzerklaerung.pdf',
      title: 'Datenschutzerklärung'
    }]
  },
  contact: {
    email: 'office@lh-holzbau.at',
    phone: '+43 07262 52141-0',
    social: {
      facebook: 'https://www.facebook.com/lhholzbaugmbh/',
      instagram: 'https://www.instagram.com/lh_holzbau/'
    },
    addresses: [{
      title: 'Betriebsstätte',
      lines: [
        'LH Holzbau GmbH',
        'Marktstraße 46',
        '4332 Au an der Donau'
      ]
    }, {
      title: 'Firmensitz',
      lines: [
        'LH Holzbau GmbH',
        'Drosselstraße 36',
        'A-4470 Enns'
      ]
    }]
  },
  home: {
    slider: [{
      image: 'data/slider/1.jpg',
      text: 'sei am\nHOLZweg.'
    }, {
      image: 'data/slider/2.jpg',
      text: 'HOLZfühl-\nfaktor'
    }, {
      image: 'data/slider/3.jpg',
      text: 'sei ein\nHOLZkopf'
    }, {
      image: 'data/slider/4.jpg',
      text: 'hab HOLZ\nvor der hütte'
    }]
  },
  sections: {
    services: {
      title: 'Leistungen',
      subtitle: 'Unser Ziel ist die Kombination von handwerklichem Geschick mit modernsten Fertigungsmethoden',
      logo: 'data/logo.svg',
      items: {
        'holzhaeuser': {
          title: 'Holzhäuser',
          text: 'Auf HOLZ bauen. Der moderne Baustoff punktet mit Vielfalt: Holz kann als Konstruktionsmaterial, Fassade oder als unbehandelte Wand- und Bodenfläche verwendet werden. Und bietet neben einer Extraportion Gemütlichkeit den Vorteil der persönlichen Note und der absoluten Gestaltungsfreiheit. Denn Häuser aus Holzriegel- oder Massivholzbau werden individuell gefertigt und sind in puncto Nachhaltigkeit kaum zu übertreffen – alle 40 Sekunden wächst der Rohstoff für ein Holzhaus zur Gänze nach.'
        },
        'zubau-aufstockung': {
          title: 'Zubau / Aufstockung',
          text: 'Wohnraumerweiterung gefällig? Kein Problem! Unser konstruktiver Holzbau eignet sich perfekt für die Aufstockung und Erweiterung von Altstadt- oder Einfamilienhäusern oder statisch und/oder architektonisch anspruchsvollen Zubauten. Durch die hauseigene CAD-gesteuerte Abbundanlage und unsere Expertise im Holzriegelbau können wir den Vorfestigungsgrad maximieren und dadurch die Umbauzeit minimieren. Durch eine dreidimensionale Planung mit modernsten Messtechniken wie etwa Laserscanning wird absolute Maßgenauigkeit auch bei komplexen Grundrissen garantiert.'
        },
        'dachstuehle': {
          title: 'Dachstühle',
          text: 'Mit unserer CNC-Abbundanlage fertigen wir Dachstühle und Überdachungen mit vielfältigen Grundrissen und jeglicher Größe millimetergenau. Die Tragwerke werden statisch optimiert, um die wirtschaftlich beste Lösung für den Kunden zu erzielen.'
        },
        'umbau-sanierung': {
          title: 'Umbau / Sanierung',
          text: 'Frischer Wind für alte Mauern: Beim Umbau oder der Sanierung von Altbauten mit Holz kombinieren wir unser Fingerspitzengefühl mit unserem Fachwissen um wirtschaftlich, baulich und technisch die beste Lösung für jedes Gebäude zu finden. Alte Dächer können durch moderne Holzdeckenkonstruktionen erneuert und von unseren Partnerunternehmen neu eingedeckt werden. Durch Sanierungen können Heizkosten gesenkt werden.'
        },
        'agrar-gebaeude': {
          title: 'Agrar-Gebäude',
          text: 'Natürliche Bauweise liegt auch in der Landwirtschaft im Trend. Wir fertigen wirtschaftlich optimierte Holzkonstruktionen wie Hallen, Stallungen, Remisen, Maschinenhallen und vieles mehr mit modernster Technik.'
        },
        'fassaden': {
          title: 'Fassaden',
          text: 'Kleider machen Leute. Und Fassaden machen Gebäude. Egal ob Verkleidungen aus Profilbrettern oder Plattenwerkstoffen – Holzfassaden stehen für das gewisse Etwas eines Bauwerkes. Die Einbringung der Wärmedämmung zwischen Fassadenverkleidung und der tragenden Wand stellt die Idealform des Wärmeschutzes dar. So wird die Holzfassade als Hingucker gleichzeitig zur wärmenden Hülle des Gebäudes.'
        },
        'innenausbau': {
          title: 'Innenausbau',
          text: 'Egal ob als Fußboden, Wand- , Dach- oder Deckenverkleidung: Ein Innenausbau mit Holz bringt ein Stück Natur ins Haus. Und mit der Natur machen sich Ruhe und Gemütlichkeit in den eigenen vier Wänden schnell breit. Wir überprüfen den vorhandenen Bestand und beraten über verschiedene Ausbaumöglichkeiten mit dem nachwachsenden Rohstoff.'
        },
        'garten-terrasse': {
          title: 'Garten / Terrasse',
          text: 'Holz im, am und rund ums Eigenheim: Der Garten als grünes Wohnzimmer wird immer mehr als Wohlfühloase und Erholungsraum genutzt. Für eine wohlverdiente ErHOLZeit im Grünen fertigen wir Terrassenbeläge, Sichtschutz, Pergoglas, Gartenhäuser, Gartenwege, Spielgeräte oder Pflanzentröge aus Holz. Holz ist dauerhaft und robust, variantenreich und individuell einsetzbar. Neben den verschiedenen Einsatzmöglichkeiten werden die wichtigsten Grundlagen in Hinblick auf Holzarten, Konstruktion, Wartung und Pflege vorgestellt.'
        },
        'carport': {
          title: 'Carport',
          text: 'Die Leidenschaften rundum geschützt: Ein HolzCarport schützt Autos, Motorräder, Wohnmobile oder Boote vor wertmindernden Einflüssen und lässt unzählige Gestaltungsmöglichkeiten zu: Ob als schlichte Ausführung, direkter Hausanbau oder als Kombination mit Geräteschuppen – wir beraten zielgerichtet und mit langjähriger Erfahrung.'
        },
        'spezialbau': {
          title: 'Spezialbau',
          text: 'Grenzenlos bauen. Mit Holz. Unter unserem Motto „Geht nicht, gibt’s nicht“ stellen wir uns allen baulichen Herausforderungen. Unsere langjährige Erfahrung, unser Know-how und handwerkliches Geschick ermöglichen die kostengünstige Ausführung auch von komplizierten Spezialkonstruktionen.'
        },
        'cnc-zuschnitte': {
          title: '<abbr>CNC</abbr>-Zuschnitte',
          text: 'Mit unserer CNC Fertigungsanlage produzieren wir millimetergenaue Zuschnitte der Hölzer-Bausätze zur Montage.'
        },
        'baustoffe': {
          title: 'Baustoffe',
          text: 'Baustoffe für Ihr persönliches Projekt: Egal ob Hölzer, Schalungen, Profilbretter, Dämmungen, Plattenwerkstoffe, Fenster, Türen oder Holzschutzfarben. und vieles mehr.'
        },
        'transport-hebearbeiten': {
          title: 'Transport & Hebearbeiten',
          text: 'Alles aus einer Hand. Unser LKW mit seinem 32 Meter Kran, ermöglicht uns Großprojekte zu realisieren. Transporte großer Bauteile können wir ebenso ohne Fremdvergabe logistisch meistern.'
        }
      }
    },
    company: {
      title: 'Unternehmen',
      subtitle: 'Überzeugung aus Tradition.',
      banner: 'data/company.jpg',
      text: 'LH Holzbau ist ein Traditionbetrieb, mit einer mehr als 100 jährigen Historie. Wir haben uns somit als eine feste Größe im Holzbaumarkt etabliert und bieten Qualität aus Meisterhand. Als Teil der Auböck Gruppe verfügt LH Holzbau über das erforderliche Umfeld für die optimale Betreuung ihrer Kunden. Unser Team wird unter der Leitung von Prokurist und Holzbaumeister Thomas Leimhofer geführt. Wir stehen Ihnen gerne mit Rat und Tat in allen Belangen ums Thema Holzbau zur Seite.'
    },
    jobs: {
      title: 'Karriere',
      subtitle: 'Lebe deine Leidenschaft.',
      text: 'Die Standorte von LH Holzbau befinden sich in Au/Donau und Enns, an denen 25 Mitarbeiter beschäftigt sind. Wir sind Experte in den Bereichen Wohn-, Gewerbe- oder landwirtschaftliche Bauten, ebenso bei den Themen Neubau, Umbau oder Sanierung.',
      line: 'Zur Verstärkung unserer Teams suchen wir:',
      badge: {
        title: 'Wir suchen\nDICH!',
        text: 'Starte durch.\nAm HOLZweg.'
      }
    },
    references: {
      title: 'Referenzen',
      subtitle: 'Qualität in allen Bereichen.',
      fullWidthTabs: false
    },
    news: {
      title: 'Aktuelles',
      subtitle: 'Immer up-to-date.',
    },
    contact: {
      title: 'Kontakt & Anfrage',
      heading: 'Kontakt',
      subtitle: 'Wir freuen uns über Ihre Anfrage mittels unten angeführtem Formular.',
      map: 'data/map.jpg',
      googleMaps: 'https://goo.gl/maps/DKapKkDGR1q2wExs6'
    },
    press: {
      title: 'Presse',
      subtitle: 'Berichte über das Unternehmen LH Holzbau.',
    },
    partners: {
      title: 'Partner',
      subtitle: 'Unsere Experten und langjährigen Zulieferer.',
      items: {
        'auboeck-bau': {
          url: 'https://www.auboeck-bau.at/',
          title: 'Auböck',
          subtitle: 'Bausgesellschaft'
        },
        'auboeck-bau-cz': {
          url: 'https://www.auboeck.cz/',
          title: 'Auböck',
          subtitle: 'Bausgesellschaft'
        },
        'die-bauwerker': {
          url: 'https://www.auboeck-bau.at/',
          title: 'Die Bauwerker'
        },
        'wolfinger': {
          url: 'https://wolfinger.at',
          title: 'Wolfinger',
          subtitle: 'Zellulosedämmung'
        },
        'keplinger': {
          url: 'https://www.keplinger.at/',
          title: 'Keplinger',
          subtitle: 'Holz und mehr'
        },
        'weiss': {
          url: 'https://www.weiss.at/',
          title: 'Weiss',
          subtitle: 'Kompetent bei Holz'
        },
        'machacek': {
          url: 'https://www.machacek.at/',
          title: 'Machacek',
          subtitle: 'Baustoffe. Dämmstoffe.'
        },
        'wuerth': {
          url: 'https://wuerth.at',
          title: 'Würth'
        },
        'velux': {
          url: 'https://www.velux.at/',
          title: 'VELUX'
        },
        'sihga': {
          url: 'https://www.sihga.com/',
          title: 'SIHGA',
          subtitle: 'Take the best'
        },
        'synthesa': {
          url: 'https://www.synthesa.at/',
          title: 'Synthesa'
        },
        'danske': {
          url: 'https://www.synthesa.at/',
          title: 'Danske'
        },
        'isover': {
          url: 'https://www.isover.at/',
          title: 'ISOVER',
          subtitle: 'Saint-Gobain'
        },
        'poschacher': {
          url: 'https://www.poschacher-baustoffe.at/',
          title: 'Poschacher Baustoffe'
        },
        'siga': {
          url: 'https://www.siga.swiss/',
          title: 'SIGA',
          subtitle: '1966'
        },
        'seca': {
          url: 'https://www.seca.at/',
          title: 'Seca',
          subtitle: 'wood profiles'
        }
      }
    },
    imprint: {
      title: 'Impressum'
    }
  }
}
