import $ from 'jquery'
import Handlebars from 'handlebars'
import 'scrollmagic/scrollmagic/uncompressed/plugins/jquery.ScrollMagic'
import config from './data/config'

$('html')
  .removeClass('no-js')
  .addClass('js')

$('.section-nav__item').first().addClass('.section__link_active')

function toggleFormLabel () {
  const input = $(this)
  input
    .closest('.form__element')
    .find('.form__label')
    .toggleClass('form__label_empty', !(input.val() || input.is(':focus')))
}

$('.form__label').addClass('form__label_empty')
$('.form__input')
  .on('focus', toggleFormLabel)
  .on('blur', toggleFormLabel)
  .on('input', toggleFormLabel)
$('.form__input_textarea').on('input', function () {
  const lines = $(this).val().split('\n').length
  $(this).css('height', (lines * 24).toString() + 'px')
})

const initMenuActivation = () => {
  const controller = new $.ScrollMagic.Controller()
  const links = $('a[href^="#"].nav__link')
  links.each(function () {
    const link = $(this)
    const sectionId = link.attr('href')
    new $.ScrollMagic.Scene({
      triggerElement: sectionId,
      triggerHook: 0.1,
      duration: $(sectionId).outerHeight()
    }).setClassToggle(this, 'nav__link_active')
      .addTo(controller)
  })

  new $.ScrollMagic.Scene({
    triggerElement: '#home',
    triggerHook: 0.1,
    duration: $('#home').outerHeight()
  }).setClassToggle($('.home__logo'), 'home__logo_home')
    .addTo(controller)
}
initMenuActivation()

function initLastChildOnLineMarker (selector, label) {
  // https://stackoverflow.com/a/9847817/925580
  $(selector).each(function () {
    let lastElement = false
    $(this).find('> *').each(function () {
      const isLast = lastElement && lastElement.offset().top !== $(this).offset().top
      if (lastElement) lastElement.toggleClass(label, isLast)
      lastElement = $(this)
    }).last().addClass(label)
  })
}

function initSmoothScroll (selector) {
  $(selector).on('click', function (event) {
    const href = $(this).attr('href')
    if (href[0] !== '#') return
    event.preventDefault()
    window.history.pushState({}, $(this).innerText, href)
    $('html, body').animate({
      scrollTop: $(href).offset().top
    }, 300)
  })
}

function initSlider (root) {
  const slider = $(root)
  const content = slider.find('.slider__content')

  const prev = slider.find('.slider__prev')
  const next = slider.find('.slider__next')
  const scrollbar = slider.find('.slider__scrollbar')
  const track = slider.find('.slider__scrollbar-track')
  const thumb = slider.find('.slider__scrollbar-thumb')

  const getScrollLeft = () => content[0].scrollLeft
  const getScrollWidth = () => content[0].scrollWidth
  const getClientWidth = () => content[0].clientWidth
  const getScrollRight = () => getScrollLeft() + getClientWidth()
  const getNeedsScroll = () => getScrollWidth() > getClientWidth()
  const getScrollRange = () => getScrollWidth() - getClientWidth()
  const getScrollOffset = () => getScrollLeft() / getScrollRange()
  const getTrackStart = () => track.offset().left
  const getTrackWidth = () => track.outerWidth()

  const placeThumb = (x, speed = 0) => {
    const offset = (x - getTrackStart()) / getTrackWidth()
    scroll(offset, speed)
  }

  const scrollBy = (amount, speed = 0) => {
    content.animate({ scrollLeft: getScrollLeft() + amount }, speed)
  }

  const scroll = (offset, speed = 0) => {
    const clamped = Math.max(0, Math.min(1, offset))
    content.animate({ scrollLeft: clamped * getScrollRange() }, speed)
  }

  const update = () => {
    const maxWidth = $('.references__sliders').outerWidth()
    content.css('width', maxWidth + 'px')
    prev.addClass('disabled')
    if (!getNeedsScroll()) {
      next.addClass('disabled')
      track.addClass('disabled')
      thumb.addClass('disabled')
    }
  }

  prev.on('click', function () {
    scrollBy(-getClientWidth() - 32, 200)
  })
  next.on('click', function () {
    scrollBy(getClientWidth() + 32, 200)
  })

  scrollbar.on('click', event => {
    placeThumb(event.clientX, 200)
  })

  thumb.on('mousedown', function () {
    thumb.addClass('dragging')
    const onMove = event => placeThumb(event.clientX)
    $(document).on('mousemove', onMove)
    $(document).one('mouseup', function () {
      thumb.removeClass('dragging')
      $(document).off('mousemove', onMove)
    })
  })

  content.on('scroll', () => {
    prev.toggleClass('disabled', getScrollLeft() === 0 || !getNeedsScroll())
    next.toggleClass('disabled', getScrollRight() === getScrollWidth())
    thumb.css('left', (getScrollOffset() * 100) + '%')
  })

  update()
  $(window).on('resize', update)
}

function lazyLoadImages (node) {
  node.find('img[data-src]').each(function () {
    $(this).attr('src', $(this).data('src'))
    $(this).removeAttr('data-src')
  })
}

function Tabs (tabs) {
  tabs.find('.tabs__tab').attr('hidden', false)
  tabs.find('.tabs__link').on('click', function (event) {
    event.preventDefault()
    const active = 'tabs__link_active'
    const visible = 'tabs__tab_visible'
    const activeLink = tabs.find('.' + active)

    activeLink.removeClass(active)
    $(this).addClass(active)

    const tab = $($(this).attr('href'))
    $(activeLink.attr('href')).removeClass(visible)
    tab.addClass(visible)
    lazyLoadImages(tab)
  })
  tabs.find('.tabs__link').first().click()
}

function initTabs () {
  $('.tabs').each(function () {
    Tabs($(this))
  })
}

function initServiceReferenceLinks () {
  initSmoothScroll('.service__link')
  $('.service__link').on('click', function () {
    const lightBox = $(this).closest('.light-box')
    lightBox.find('.light-box__close').click()
    const href = '#' + lightBox.attr('id') + '-referenzen'
    $(`a[href="${href}"]`).click()
  })
}

function initHomeSlider () {
  const list = $('.home__slider-list')
  const slide = (speed = 0) => {
    const first = $('.home__slider-item:first-child')
    const last = $('.home__slider-item:last-child')
    list
      .finish()
      .animate({ left: -first.outerWidth() }, speed, null, () => {
        $('.home__slider-list').css('left', 0)
        first.insertAfter(last)
      })
  }
  setInterval(() => slide(400), 4000)
}

function initBurgerButton () {
  const nav = $('.nav__list')
  nav.on('click', function (event) {
    event.stopPropagation()
    nav.toggleClass('nav__list_closed')
  })
  $(document).on('click', function () {
    nav.addClass('nav__list_closed')
  })
}

// Open light-box with service description.
function initServiceLinks () {

  // setup light-boxes
  $('.services__list .light-box').each(function () {
    $(this).attr('hidden', false)
    $(this).find('.light-box__close').on('click', function () {
      $(this).closest('.light-box').removeClass('light-box_visible')
    })
  })

  const visible = 'light-box_visible'
  $('.service-link').on('click', function (event) {
    event.preventDefault()
    $('.' + visible).removeClass(visible)
    const lightBox = $($(this).attr('href'))
    lightBox.addClass(visible)
    lazyLoadImages(lightBox)
  })
}

// Open light-box with article when clicking on slider tile.
// Initially, articles where rendered immediately but hidden.
// All this hidden images seem to be too much for some Safari versions.
// Instead only render articles when they are opened.
function initTileLinks (data) {

  // create Handlebars template
  const lightBoxContainer = document.createElement('div')
  lightBoxContainer.setAttribute('id', 'temp-light-box')
  document.body.appendChild(lightBoxContainer)
  const articleTemplate = Handlebars.compile(`{{> article this}}`)

  // put all articles into single list
  const articles = Object.values(data.references)
    .reduce((a, x) => [...a, ...x], [])
    .concat(data.news)
    .reduce((a, x) => ({ ...a, [x.id]: x }), {})

  // render new light-box on click
  $('.tile-link').on('click', function (event) {
    event.preventDefault()

    // replace old light-box with new one
    const articleId = $(this).attr('href').substr(1)
    const articleData = articles[articleId]
    lightBoxContainer.innerHTML = articleTemplate(articleData)

    // init light-box behavior
    const lightBox = $(lightBoxContainer.children[0])
    lightBox.addClass('light-box_visible')
    lightBox.find('.light-box__close').on('click', function () {
      lightBox.removeClass('light-box_visible')
    })
    lazyLoadImages(lightBox)
    Tabs(lightBox.find('.tabs'))
  })
}

function init (data) {
  $(window).on('resize', () => {
    initLastChildOnLineMarker('.references__nav-list', 'last-on-line')
  })

  $('.slider').each(function () {
    initSlider(this)
  })

  initSmoothScroll('a.badge')
  lazyLoadImages($('.news__slide'))
  lazyLoadImages($('.news2'))
  initLastChildOnLineMarker('.references__nav-list', 'last-on-line')
  initServiceReferenceLinks()
  initTabs()
  initSmoothScroll('a.nav__link[href^="#"]')
  initSmoothScroll('a.home__logo')
  initSmoothScroll('.home__next')
  initSmoothScroll('.news__job')
  initHomeSlider()
  initBurgerButton()
  initServiceLinks()
  initTileLinks(data)
}

// load API data for rendering dynamics content
const apiUrl = config.api[config.env] + '../admin/api.php'
$.get(apiUrl, data => {
  // locate handlebars partials and dynamics content templates
  const mimeType = 'text/x-handlebars-template'
  const selector = `script[type="${mimeType}"]`
  const partials = $('head ' + selector).toArray()
  const templates = $('body ' + selector).toArray()

  // init handlebars
  Handlebars.registerHelper('api', require('./helpers/api'))
  Handlebars.registerHelper('equals', require('./helpers/equals'))
  Handlebars.registerHelper('linebreaks', require('./helpers/linebreaks'))

  partials.forEach(script => {
    Handlebars.registerPartial(script.dataset.name, script.innerText)
  })

  // replace each template with rendered DOM
  templates.forEach(script => {
    const template = Handlebars.compile(script.innerText)
    script.outerHTML = template({
      api: data,
      // NOTE: in some cases we need to pass data from build-time templates
      // e.g. the reference slider list gets its order from config.js
      // this data can be put into the 'data-x' attribute of the script element
      // here we unpack the dataset and pass it as additional data to the template
      // it can be accessed in the template via 'dom.x'
      dom: Object.keys(script.dataset)
        .reduce((a, key) => ({ ...a, [key]: script.dataset[key] }), {})
    })
  })

  // init dynamic GUI after templates have been rendered
  init(data)
}, 'json')
