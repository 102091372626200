const config = require('./../data/config.js')

module.exports = (path, key, extension) => {
  const base = (config.env === 'prod'
    ? config.api.prod
    : config.api.dev)
  const url = base + path
  if (key.toString() !== key) return url
  return url + '/' + key + '.' + extension
}
